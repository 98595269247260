.contact {
  display: flex;
  justify-content: center;
  margin-top: 200px;
  height: 200px;
}

.contact-container {
  width: 75%;
  display: flex;
  justify-content: center;
}

.social {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-right: 40px;
}
.social span {
  font-size: 0.8em;
  color: #1dddbd;
}

.social-link {
  color: white;
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 10px;
  font-size: 1.4em;
  font-weight: 400;
  position: relative;
}
.social-link:hover {
  color: #1dddbd;
}

.email::after {
  content: "";
  background: url("../../content/gmail.png") no-repeat;
  background-size: cover;
  z-index: 3;
  position: absolute;
  right: -40px;
  top: 4px;
  height: 25px;
  width: 25px;
  animation: float 2s ease-in-out infinite;
}

.linkedin::after {
  content: "";
  background: url("../../content/linkedin.png") no-repeat;
  background-size: cover;
  z-index: 3;
  position: absolute;
  right: -40px;
  top: 4px;
  height: 25px;
  width: 25px;
  animation: float 2s ease-in-out infinite;
}

.facebook::after {
  content: "";
  background: url("../../content/facebook.png") no-repeat;
  background-size: cover;
  z-index: 3;
  position: absolute;
  right: -40px;
  top: 4px;
  height: 25px;
  width: 25px;
  animation: float 2s ease-in-out infinite;
}

.instagram::after {
  content: "";
  background: url("../../content/instagram.png") no-repeat;
  background-size: cover;
  z-index: 3;
  position: absolute;
  right: -40px;
  top: 4px;
  height: 25px;
  width: 25px;
  animation: float 2s ease-in infinite;
}

@keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -3px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@media (max-width: 950px) {
  .contact {
    margin-top: 100px;
    height: 350px;
  }
  .contact-container {
    flex-direction: column;
    align-items: center;
  }
  .social {
    align-items: center;
    margin-bottom: 20px;
  }
}/*# sourceMappingURL=contact.css.map */