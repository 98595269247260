.home {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    color: #1dddbd;


    h1 {
        font-size: 3em;
        line-height: 0.9em;
    }


    #front-end {
        color: white;
        font-size: 3em;
    }

    h2 {
        font-size: 2.4em;
    }

    h3 {
        font-size: 1.7em;
        color: white;
    }
}

.home-headings-container {
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    width: 500px;
}

.home-headings {
    width: 100%;
}

.home-container {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.home-image-container {
    height: 400px;
    width: 400px;
    border-radius: 50%;
}

.home-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: rgb(255, 255, 255) 0px 3px 6px, rgb(255, 255, 255) 0px 3px 6px;

}


.cv-link {
    background-color: #1dddbd;
    border-radius: 10px;
    color: #fff;
    font-size: 0.9em;
    font-weight: 500;
    padding: 10px 20px;
    text-decoration: none;
    transition: padding .3s;
    width: fit-content;
    margin-top: 30px;
    margin-left: 10px;

    &:hover {
        background-color: #16b097;
        color: white;
        padding: 10px 30px;
    }
}



@media (min-width:1900px) {
    .home-headings-container {
        margin-right: 200px;
    }

}


@media (max-width:1350px) {
    .home-headings-container {
        margin-right: 50px;
        width: 400px;
    }

}

@media (max-width:1150px) {

    .home-image-container {
        height: 300px;
        width: 300px;
    }
}


@media (max-width:1000px) {

    .home-headings-container {
        margin-right: 25px;
    }

    .home-image-container {
        height: 250px;
        width: 250px;
    }
}


@media (max-width:950px) {

    .home {
        margin-top: 40px;
    }

    .home-container {
        flex-direction: column;
    }

    .home-headings-container {
        align-items: center;
        margin-bottom: 40px;
        margin-right: 0;
    }

    .home-headings {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}



@media (max-width:450px) {


    .home-headings-container {
        width: 300px;
    }

    
}