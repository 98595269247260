.about {
    display: flex;
    justify-content: center;
    margin-top: 250px;
}

.about-me-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 100px;
}

.skills-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
}

.about-container {
    display: flex;
    width: 75%;
    justify-content: center;

    h2 {
        margin-bottom: 12px;
        font-size: 2em;
    }

    p {
        font-size: 1em;
        width: 500px;

    }
}


.about-link {
    text-decoration: none;
    color: #1dddbd;
    white-space: nowrap;


    &:hover {
        color: white;
    }
}


.tech-stack {
    display: flex;

}

.tech-stack-img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 50ms ease-in-out;

    &:hover {
        transform: scale(1.1);
        box-shadow: rgb(255, 255, 255) 0px 1px 3px, rgb(255, 255, 255) 0px 3px 6px;
    }


}






.tech-stack-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;

    span {
        font-size: 0.7em;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}


@media (min-width:1900px) {
    .about-me-section {
        margin-right: 200px;
    }

}


@media (max-width:1200px) {

    .about {
        margin-top: 150px;
    }

    .about-container {
        flex-direction: column;
        align-items: center;

        p {
            text-align: center;
        }
    }

    .about-me-section {
        margin-right: 0;
        margin-bottom: 40px;
    }
}



@media (max-width:750px) {


    .tech-stack {
        display: grid;
        grid-template-columns: 60px 60px 60px 60px;
        gap: 20px;
    }
}



@media (max-width:500px) {

    .tech-stack {
        gap: 10px;
    }

    .tech-stack-img{
        width: 40px;
        height: 40px;
    }

    .about-container {
        p {
            width: 400px;
        }
    }

    .skills-section{
        width: 300px;
    }
}


@media (max-width:450px) {


    .tech-stack-img{
        width: 40px;
        height: 40px;
    }

    .about-container {
        p {
            width: 300px;
        }
    }
}