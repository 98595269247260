.projects {
    margin-top: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.projects-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-size: 2em;
        margin-bottom: 20px;
    }
}


.projects-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: 300px 300px 300px 300px;
    grid-template-rows: 200px 200px;
    border-radius: 10px;
    padding: 20px;
}

.project {
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.project-title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 0.8em;
}

.project-link {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1dddbd;
    border-radius: 10px;
    color: #fff;
    font-size: 0.8em;
    font-weight: 400;
    padding: 10px 20px;
    text-decoration: none;
    transition: padding .2s;
    white-space: nowrap;

    &:hover {
        background-color: #16b097;
        color: white;
        padding: 10px 25px;
    }
}

.project-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;

    filter: brightness(0.5);

}

.hovered {
    filter: brightness(1);

}



@media (max-width:1400px) {
    .projects {
        margin-top: 150px;
    }

    .projects-grid {
        grid-template-columns: 300px 300px 300px;
        grid-template-rows: 200px 200px 200px;
    }
}




@media (max-width:1200px) {
    .projects-grid {
        grid-template-columns: 300px 300px;
        grid-template-rows: 200px 200px 200px 200px;
    }
}



@media (max-width:750px) {

    .projects-grid {
        grid-template-columns: 300px;
        grid-template-rows: 200px 200px 200px 200px 200px 200px 200px 200px;
    }
}


@media (max-width:400px) {

    .projects-grid {
        grid-template-columns: 250px;
        grid-template-rows: 160px 160px 160px 160px 160px 160px 160px 160px;
       padding-top: 20px 0 0 0;
    }
}