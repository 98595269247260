.navbar,
.navbar-mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  top: 0;
  position: fixed;
  background-color: #232323;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;
  z-index: 5;
}

.nav-logo {
  width: 60px;
  height: 40px;
  -o-object-fit: contain;
     object-fit: contain;
  position: absolute;
  top: 15px;
  left: 20px;
}

.nav-container {
  width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.nav-links .active {
  position: relative;
  color: #1dddbd;
}
.nav-links .active::after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.nav-link {
  color: white;
  text-decoration: none;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 1.2em;
}
.nav-link:hover {
  color: #1dddbd;
}

.mobile-menu-icon {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
}

.mobile-menu-close-icon {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (max-width: 950px) {
  .nav-link {
    font-size: 1.5em;
  }
  .navbar {
    height: 100vh;
    touch-action: none;
  }
  .mobile-menu-icon {
    display: block;
  }
  .nav-links {
    flex-direction: column;
  }
  .nav-link {
    margin-bottom: 20px;
  }
  .mobile {
    display: none;
  }
}/*# sourceMappingURL=navbar.css.map */