*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-weight: 300;
}

body{
    background-color: #232323;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    color: white;
}

main{
    position: relative;
    top: 60px;
}

@media (min-width:1900px) {
    body{
        font-size: 24px;
    }
}


@media (max-width:1350px) {
    body{
        font-size: 18px;
    }

}

@media (max-width:1000px) {
    body{
        font-size: 16px;
    }
}


